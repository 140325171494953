import { AdSize, NativeBackfillFunction, PublisherSlotAPI, SlotOptions, SystemSlotEvent } from '@mbrtargeting/metatag-shared-types/metatag-core';
import { ensureArray, isStringArray } from '@mbrtargeting/metatag-utils';
import { isAdSizes } from '../utils/adsize-helper.js';
import { markSdg } from '../utils/performance.js';
import { triggerEvent } from './essentials/events.js';
import { logger } from './essentials/logger.js';
import { KeyValues, Values } from './key-values.js';

const log = logger.logGroup({ prefix: 'Adslot' });

type SlotStubOption = Partial<SlotOptions>

export class AdslotStub implements PublisherSlotAPI {

    private impl?: PublisherSlotAPI;
    private loadMarker: boolean = false;
    protected readonly targeting: KeyValues = new KeyValues();
    protected readonly blockedFormats = new Values<string>();
    protected readonly blockedSizes: AdSize[] = [];
    protected localZone: string = '';
    protected localPageType: string = '';
    public nativeBackfillFunction?: NativeBackfillFunction;
    public stubOptions: SlotStubOption = {}; //to avoid overwriting publisher input later on, keep stubOptions separated from real adslot options

    constructor(
        public readonly slotName: string,
        private readonly slotContainerIdOrElement: string | HTMLElement,
    ) { }

    /**
     * cleanup function when deleting the adslot
     */
    public deconstructor() {
        const adslot = this;
        triggerEvent(SystemSlotEvent.SDG_SLOT_DELETED, {
            passedObject: adslot,
            placement: adslot,
            slot: adslot.slotName,
            position: adslot.slotName,
            slotName: adslot.slotName,
        });
        log.debug('%s: slot-stub successfully deleted.', [adslot.slotName.toUpperCase()])
    }

    /**
     * Replaces this stub with the real implementation.
     *
     * @param Class the Adslot implementation
     */
    public setImpl<Class extends PublisherSlotAPI>(Class: { new(slotName: string, slotContainer: string | HTMLElement): Class }): PublisherSlotAPI {
        this.impl = new Class(this.slotName, this.slotContainerIdOrElement);
        this.impl.setTargeting(this.targeting.toObject());
        this.impl.blockFormats(this.blockedFormats.toArray());
        this.impl.removeSizes(this.blockedSizes);
        this.impl.configure(this.stubOptions);
        this.impl.setZone(this.localZone);
        this.impl.setPageType(this.localPageType);
        if (this.nativeBackfillFunction) {
            this.impl.nativeBackfill(this.nativeBackfillFunction);
        }
        if (this.loadMarker) {
            this.impl.load();
        }
        return this.impl;
    }

    /**
     * @inheritdoc
     */
    public load(): PublisherSlotAPI {
        markSdg(`publisher-requests-an-ad_mt_${this.slotName}`);
        if (this.impl) {
            return this.impl.load();
        }
        this.loadMarker = true;
        return this;
    }

    /**
     * @inheritdoc
     */
    public setTargeting(keyValues: Record<string, string | string[]>): PublisherSlotAPI {
        if (this.impl) {
            return this.impl.setTargeting(keyValues);
        }
        for (const [key, valueOrValues] of Object.entries(keyValues)) {
            this.targeting.addValues(key, ensureArray(valueOrValues));
        }
        return this;
    }

    /**
     * @inheritdoc
     */
    public removeTargeting(key: string, value?: string | undefined): PublisherSlotAPI {
        // experiment: guessing someone is deleting all keys; skip removal of some protected keys
        if (['as', 'adslot', 'af'].includes(key)) return this;

        if (this.impl) {
            return this.impl.removeTargeting(key, value);
        }
        this.targeting.deleteValues(key, value ? [value] : undefined);
        return this;
    }

    /**
     * @inheritdoc
     */
    public blockFormats(formats: string[]): PublisherSlotAPI {
        if (!isStringArray(formats)) {
            log.error('%s: blockFormats(): Formats were not passed as array, please use an array to indicate which formats should be blocked.', [this.slotName]);
            return this;
        }
        if (this.impl) {
            return this.impl.blockFormats(formats);
        }
        this.blockedFormats.addAll(formats);
        return this;
    }

    /**
     * @inheritdoc
     */
    public removeSizes(sizes: AdSize[]): PublisherSlotAPI {
        if (!isAdSizes(sizes)) {
            log.error('%s: removeSizes(): sizes not an adsizes array - ignoring', [this.slotName]);
            return this;
        }
        if (this.impl) {
            return this.impl.removeSizes(sizes);
        }
        this.blockedSizes.push(...sizes);
        return this;
    }

    /**
     * @inheritdoc
     */
    public nativeBackfill(publisherBackfill: NativeBackfillFunction): PublisherSlotAPI {
        if (this.impl) {
            return this.impl.nativeBackfill(publisherBackfill);
        }
        this.nativeBackfillFunction = publisherBackfill;
        return this;
    }

    /**
     * @inheritdoc
     */
    public configure(options: Partial<SlotOptions>): PublisherSlotAPI {
        if (this.impl) {
            return this.impl.configure(options);
        }
        this.stubOptions = Object.assign(this.stubOptions, options);
        return this;
    }

    /**
     * @inheritdoc
     */
    public setZone(zone: string): PublisherSlotAPI {
        if (this.impl) {
            return this.impl.setZone(zone);
        }
        this.localZone = zone;
        return this;
    }

    /**
     * @inheritdoc
     */
    public setPageType(pageType: string): PublisherSlotAPI {
        if (this.impl) {
            return this.impl.setPageType(pageType);
        }
        this.localPageType = pageType;
        return this;
    }

    public getBlockedFormats(): string[] {
        return this.blockedFormats.toArray();
    }

    public getBlockedSizes(): AdSize[] {
        return this.blockedSizes;
    }

    /**
     * @inheritdoc
     */
    public toJSON() {
        return {
            slotName: this.slotName,
        };
    }
}
